@import "./css/variables";

html,
body,
#root {
    height: 100vh;
}

html body {
    background-color: $editor-panel-bg-color;
}

html header {
    background-color: $maroon;
}

.nav-link {
    cursor: pointer;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.navbar-brand {
    img {
        height: 65px;
    }
}

// header .navbar-dark .navbar-nav {
//     .nav-link {
//         color: rgba($orange, .5);
//     }

//     .show > .nav-link,
//     .active > .nav-link,
//     .nav-link.show,
//     .nav-link.active
//     {
//         color: $orange;    
//     }
// }

.flex {
    display: flex;
}

ul.nav.nav-tabs {
    &.word-tab-btns {
        border-bottom: none;
        margin-bottom: 2px;

        li.nav-item {
            z-index: 5;

            a.nav-link {
                border-color: $editor-panel-bg-color;

                &.editor-active {
                    //border-bottom-color: $page-outline-color;
                    border-bottom: 0;
                }

                &.active {
                    $borderColor: #ccc;
                    border-top-color: $borderColor;
                    border-left-color: $borderColor;
                    border-right-color: $borderColor;
                    border-bottom: 1px solid $editor-panel-bg-color;

                    &.editor-active {
                        background-color: $editor-panel-bg-color;
                        border-top-color: $page-outline-color;
                        border-left-color: $page-outline-color;
                        border-right-color: $page-outline-color;
                    }
                }
            }
        }
    }
}

.btn-add {
    position: relative;
    top: 7px;
    margin-left: 10px;
    padding: 0px 6px 2px;
    background: lighten($green-is-true, 10%);
    color: #EEE;
    font-weight: bold;
    font-size: 26px;
    line-height: 1;

    &.active,
    &:hover {
        background: $green-is-true;
        text-decoration: none;
        color: #FFF;
    }
}

audio {
    outline: none;
}

.debug_notice {
    color: $orange;
    font-style: italic;
}
