$maroon: #60384a;
$grey: #474452;
$orange: #ec6743;


$green-is-true: #4e4;
$red-is-false: #e44;

$editor-panel-bg-color: #efefef;

$table-row-hover-darken-strength: 10%;

$page-outline-color: #CCC;
