@import '../../css/variables';


.wordListHeader,
.listRow {
    display: flex;

    // > span,
    // > .th {
    //     flex-basis: 33.33%;
    // }
}

.wordListHeader {
    font-weight: bold;
}

.th,
.td {
    padding: 6px 10px;
}

.th {
    text-transform: capitalize;

    &.audiofile,
    &.boolean,
    &.number,
    &.Translated,
    &.Duplicates {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
    }

    &.sort-col {
        &:after {
            content: "▲";
        }

        &.desc {
            &:after {
                content: "▼";
            }
        }
    }
}

.td {
    &.audiofile,
    &.boolean {
        text-align: center;
        font-weight: bold;
        color: #fff;

        &.is-false {
            background-color: $red-is-false;
        }
        &.has-duplicates {
            background-color: #ffc107;
        }
        &.is-true {
            background-color: $green-is-true;
        }
    }

    &.number,
    &.translated,
    &.duplicates {
        text-align: right;
        padding-right: 4.5%;
    }
}

.listRow {
    cursor: pointer;
    color: #212529;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: #212529;
        background: darken(#fff, $table-row-hover-darken-strength);

        .td {
            &.audiofile,
            &.boolean {
                &.is-false {
                    background-color: darken($red-is-false, $table-row-hover-darken-strength);
                }
                &.has-duplicates {
                    background-color: darken(#ffc107, $table-row-hover-darken-strength);
                }
                &.is-true {
                    background-color: darken($green-is-true, $table-row-hover-darken-strength);
                }
            }
        }
    }
}

.add_new_wrapper,
.editor_panel_wrapper {
    display: flex;
    border: 1px solid $page-outline-color;
    padding: 0;
    background: $editor-panel-bg-color;
    position: relative;
    top: -1px;

    > .btn {
        position: absolute;
        right: -1px;
        top: -1px;
        border-radius: 0;
    }

    form {
        label {
            text-transform: capitalize;
        }
        input {
            text-transform: none;
        }
    }

    .editor_panel {
        padding: 20px 50px 0px 30px;
        width: 100%;
    }
}

.loading_notice {
    position: absolute;
    top: 10px;
}

.form-buttons {
    display: flex;

    button {
        font-weight: bold;
        letter-spacing: 1px;
        
        &:first-child {
            margin-right: auto;
        }
    }
}

.word_previews {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid $page-outline-color;

    .preview_position {
        font-family: proxima-nova, sans-serif;
        color: #474452;
        box-sizing: border-box;
        flex: 0 0 29%;
        min-height: 140px;
        margin-bottom: 30px;

        &.english {
            .preview_trnsl {
                padding-inline-start: 0;
                font-size: .9em;
            }

            .preview_pron {
                font-size: 1em;
                margin-bottom: 0;
            }
        }
    }

    .preview_box {
        display: block;
        color: initial;
        position: relative;
        padding: 10px 15px;
        border: 1px solid rgba(71, 68, 82, 0.3);
        min-height: 120px;

        &:hover {
            box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.15);
            text-decoration: none;
        }
    }

    .preview_word,
    .preview_pron,
    .preview_trnsl {
        padding-right: 30px;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .preview_word {
        font-family: skolar-latin, serif;
        font-weight: 600;
        font-size: 1.35em;
        line-height: 1.25;
        padding-right: 8px;

        p {
            margin: 0 !important;
        }
    }

    .preview_pron {
        font-family: sans-serif;
        line-height: 1.25;
    }

    .preview_trnsl {
        font-size: 14px;
        padding-inline-start: 20px;
    }

    .preview_pos {
        color: #474452;
        opacity: 0.7;
        font-style: italic;
        margin-top: 3px;

        + .preview_pos {
            margin-left: 8px;
        }
    }

    .preview_play_btn {
        cursor: pointer;
        position: absolute;
        z-index: 9;
        bottom: 0;
        right: 0;
        width: 70px;
        height: 70px;
        background-size: 30px;
        overflow: hidden;
        text-indent: -1000px;
        background-color: transparent;
        background-image: url(/images/play-icon-dark.svg);
        background-repeat: no-repeat;
        background-position: center;
        border: none;

        &:disabled {
            cursor: auto;
            opacity: 0.5;
        }
    }
}

form {
    button + button {
        margin-left: 15px;
    }
}

.jump-to-row {
    display: flex;
    padding: 5px 10px;
}

.jump-to-letters {
    display: flex;
    flex-grow: 1;
    padding-left: 15px;

    $jtBtnColor: rgb(152, 9, 180);
    .jump-to-button {
        flex-grow: 1;
        border: 0;
        background: none;
        color: $jtBtnColor;
        font-weight: bold;

        &:hover {
            color: darken($jtBtnColor, 10%);
        }

        &:disabled {
            color: gray;
        }
    }
}

.translated_words {
    button {
        margin-right: 5px;
        outline: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 4px 6px;

        // &:hover {
        //     //
        // }

        &:last-child {
            margin-right: 0;
        }
    }
}

.form-control {
    &.non-editable {
        cursor: default;
    }
}

label {
    &.form-control.id {
        display: inline-block;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.duplicate_links {
    a {
        margin-right: 4px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.delete_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal_body {
        padding: 40px;
        background-color: $editor-panel-bg-color;

        h1 {
            text-align: center;
            font-weight: bold;
            text-transform: uppercase;
        }

        .buttons {
            position: relative;
        }

        button, .btn {
            font-weight: bold;

            &:last-child {
                position: absolute;
                right: 0;
            }
        }
    }
}

.add_new_wrapper {
    border: 1px solid $green-is-true;
}

@import "./list/fields/styles.scss";
