
.id_duplicates_tabs.nav-tabs {
    // position: absolute;
    // top: -1px;
    // left: 1px;
    // transform: translateX(-100%);
    border-bottom: none;
    background: #DDD;
    width: 260px;
    min-width: 260px;


    .nav-item {
        // border-color: $editor-panel-bg-color;
        // border-bottom: 1px solid $editor-panel-bg-color;

        .nav-link {
            border-radius: 0;
            border: 0;
            border-top: 1px solid $editor-panel-bg-color;
            border-bottom: 1px solid $editor-panel-bg-color;
            margin-bottom: 0;
            // margin-right: -1px;
            // border-right: none;
            text-align: center;
            position: relative;
            padding: 12px 4px;

            &:first-child {
                margin-top: 2px solid $editor-panel-bg-color;
            }

            &:last-child {
                margin-bottom: 2px solid $editor-panel-bg-color;
            }

            &:hover {
                background: #CCC;
            }

            &.active {
                background-color: $editor-panel-bg-color;
                // border-right-color: $editor-panel-bg-color;

                // border-top-color: $page-outline-color;
                // border-left-color: $page-outline-color;
                // border-bottom-color: $page-outline-color;

                color: black;
                font-weight: bold;
            }

            .record_id {
                font-size: 11px;
                font-weight: normal;
                position: absolute;
                top: 2px;
                left: 3px;
            }

            .record_hidden {
                position: absolute;
                font-size: 11px;
                font-weight: normal;
                font-style: italic;
                top: -1px;
                right: 0;
            }

            .record_word {
                font-size: 14px;

                p {
                    margin-bottom: 4px;
                }
            }

            .record_trans {
                position: absolute;
                text-align: right;
                font-size: 12px;
                font-weight: normal;
                font-style: italic;
                bottom: 0;
                right: 2px;
                text-overflow: ellipsis;
                width: 240px;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
    
    .debug_notice {
        padding: 0.5rem 1rem;
    }

    .duplicates_header {
        font-weight: bold;
        text-align: center;
        padding: 10px 0;
    }
}

.existing-audio-file {
    position: relative;

    ~ .form-text {
        position: absolute;
        right: 15px;
    }

    ~.btn-remove-audio {
        position: absolute;
        top: 8px;
        margin-left: 10px;
    }
}

.existing-url-spacer {
    opacity: 0;
    pointer-events: none;
}